import { createRouter, createWebHistory } from 'vue-router'

import { useAuthStore } from '../store/auth.store.js';

import LoginView from '../views/LoginView.vue'
import RegistroView from '../views/RegistroView.vue'
import RecuperarContrasenaView from '../views/RecuperarContrasenaView.vue'
import CambiarContrasenaView from '../views/CambiarContrasenaView.vue'
import ActivateView from '../views/ActivateView.vue'
import LibrosView from '../views/LibrosView.vue'
import BibliotecaView from '../views/BibliotecaView.vue'
import AutoresView from '../views/AutoresView.vue'
import NotificacionesView from '../views/NotificacionesView.vue'
import InteriorLibroView from '../views/InteriorLibroView.vue'
import InteriorAutorView from '../views/InteriorAutorView.vue'
import PostView from '../views/PostView.vue'
import ProcesarQrView from '../views/ProcesarQrView.vue';
import QrView from '../views/QrView.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home/:id?',
    name: 'libros',
    component: LibrosView
  },
  {
    path: '/biblioteca',
    name: 'biblioteca',
    component: BibliotecaView
  },
  {
    path: '/autores',
    name: 'autores',
    component: AutoresView
  },
  {
    path: '/autor/:id',
    name: 'autor',
    component: InteriorAutorView
  },
  {
    path: '/notificaciones',
    name: 'notificaciones',
    component: NotificacionesView
  },
  {
    path: '/libro/:id',
    name: 'libro',
    component: InteriorLibroView
  },  
  {
    path: '/post/:idDestacado',
    name: 'post',
    component: PostView
  },  
  {
    path: '/registro',
    name: 'registro',
    component: RegistroView
  },
  {
    path: '/recuperar-contrasena',
    name: 'recuperar-contrasena',
    component: RecuperarContrasenaView
  },
  {
    path: '/activate',
    name: 'activate',
    component: ActivateView
  },
  {
    path: '/cambiar-contrasena',
    name: 'cambiar-contrasena',
    component: CambiarContrasenaView
  },
  {
    path: '/qr',
    name: 'qr',
    component: QrView
  },
  {
    path: '/procesar-qr/:code',
    name: 'procesar-qr',
    component: ProcesarQrView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/' ,'/registro', '/recuperar-contrasena', '/cambiar-contrasena', '/activate',];
  var authRequired = !publicPages.includes(to.path);
  if ( to.path.includes('/procesar-qr/') )  { 
    authRequired = false;
  } 
  const auth = useAuthStore();

  if (authRequired && !auth.user) {
      auth.returnUrl = to.fullPath;
      return '/';
  }
});