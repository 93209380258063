import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers';
const baseUrl = process.env.VUE_APP_API_BASE + '/code/scan/';

export const useQRStore = defineStore('qr',{
    state: () => ({
      codeQr: null,
      response: null,
      anadido: false
    }),
    actions: {
      async fetchQr(codeQr) {
        if ( codeQr == null || codeQr == "" ) {
          return false;
        }
        this.codeQr = codeQr;
        const response = await fetchWrapper.get( baseUrl + codeQr );
        console.log('response:', response);
        this.response = response;
      },
      async fetchQrDirect() {
        if ( this.codeQr == null ) {
          return false;
        }
        const response = await fetchWrapper.get( baseUrl + this.codeQr );
        this.codeQr   = null;
        this.response = response;
        this.anadido  = true;
      }
    },
     
  })
