<template>
    <div  v-if="sepuede" class="login mx-auto">

        <div class="logincont">
            <section class="logologin">
                <img src="@/assets/img/logologin.png" class="mx-auto" alt="Mas que lectura" />
            </section>
            <section class="cont-form">
                <form @submit.prevent="onSubmit" class="form-login">

                    <div class="cont-pass">
                        <input class="" name="password" :type="activeField === 'password' && showPassword ? 'text' : 'password'" v-model="form.password" placeholder="Nueva contraseña" title="La contraseña deberá tener mínimo 1 Mayuscula, 1 Número y una longitud de 8 caracteres" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$" />
                        <img class="ver-pass" :src="activeField === 'password' && showPassword ? hidepassimg : showpassimg" @click="togglePasswordVisibility('password')">
                    </div>
                    <div v-if="errors.password" class="cont-error"><small>{{ errors.password }}</small></div>   
                    <div class="cont-pass">
                        <input class="" name="password_confirmation" :type="activeField === 'confirmation' && showPassword ? 'text' : 'password'" v-model="form.password_confirmation" placeholder="Repetir contraseña" title="La contraseña deberá tener mínimo 1 Mayuscula, 1 Número y una longitud de 8 caracteres" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$" />
                        <img class="ver-pass" :src="activeField === 'confirmation' && showPassword ? hidepassimg : showpassimg" @click="togglePasswordVisibility('confirmation')">
                   
                    </div>
                    <div v-if="errors.password_confirmation" class="cont-error"><small>{{ errors.password_confirmation }}</small></div>
                    <div class="cont-form" style="text-align: center;margin: 10px 0px">
                        <small v-if="errorShow">{{errorText}}</small>
                    </div>                 
                    <input class="inputbutton" type="submit" value="Cambiar contraseña">
                </form>
                <div class="link-form">
                    <router-link to="/">Volver al login</router-link>
                    <router-link to="/registro">Crear cuenta</router-link>
                </div>
                
            </section>
        </div>

    </div>

</template>

<script setup>
    import { ref } from 'vue';
    import { storeToRefs } from 'pinia';
    import { useCambiarStore } from '@/store/cambiar_contrasena.store' 
    import { useRoute } from 'vue-router';  
    import router from '@/router'

    const cambiarContrasena =  useCambiarStore();
    const { sepuede, ok } = storeToRefs(cambiarContrasena);
    const form = ref({
        password: '',
        password_confirmation: ''
    });
    const errors    = ref({});   
    const route = useRoute();
    /* Comprobamos si se ha hecho la petición */
    //const { sepuede } = storeToRefs( cambiarContrasena);
    
    cambiarContrasena.comprobarDatos( route.query );
    console.log(sepuede.value);
    function onSubmit() {
        errors.value= {};
        // Errors
        if (!form.value.password) {
            errors.value.password = "El campo Contraseña es obligatorio";
        }
        if (!form.value.password_confirmation) {
            errors.value.password_confirmation = "El campo Repite Contraseña es obligatorio";
        }
        if (form.value.password !== form.value.password_confirmation) {
            errors.value.password_confirmation = "Las contraseñas no coinciden";
        }
        if (Object.keys(errors.value).length > 0) {
            return;
        }
        //////////////////////////////
        const { password, password_confirmation } = form.value;    
        const datos = { password:password, password_confirmation:password_confirmation, user: route.query.u, token: route.query.t };
        cambiarContrasena.cambiarContrasena(datos).then(()=>{
            if ( ok.value == true ) {
                router.push('/?ok=1&module=password&sub=change');
            }
        });    
    }

    const showPassword = ref(false);
    const activeField = ref('password');
    import verPassImg from '@/assets/img/ver.png';
    import esconderImg from '@/assets/img/esconder.png';
    const showpassimg = ref(verPassImg);
    const hidepassimg = ref(esconderImg);

    function togglePasswordVisibility(field) {
    if (activeField.value === field) {
        showPassword.value = !showPassword.value;
    } else {
        showPassword.value = true;
        activeField.value = field;
    }
    }


</script>


<style scoped> 

    


  

</style>


  

   
