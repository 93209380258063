<template>
    <div class="login mx-auto" v-if="cargado">
    
        <div class="logincont">
            <section class="logologin">
                <img src="@/assets/img/logologin.png" class="mx-auto" alt="Mas que lectura" />
            </section>
            <section class="cont-form">
                <form @submit.prevent="onSubmit" class="form-login">
                    <input class="inputuser user" type="text" v-model="form.email" placeholder="Usuario" />
    
                    <div class="cont-pass">
                        <input class="inputpass" :type="activeField === 'password' && showPassword ? 'text' : 'password'" v-model="form.password" placeholder="Contraseña" />
                        <img class="ver-pass" :src="activeField === 'password' && showPassword ? hidepassimg : showpassimg" @click="togglePasswordVisibility('password')">
                    </div>
                    <div class="cont-form" style="text-align: center;margin: 10px 0px;color:red">
                        <small v-if="errorShow">{{errorText}}</small>
                    </div>
                    <input class="inputbutton" type="submit" value="Entrar">
                </form>
                <div class="link-form recu-pass">
                    <router-link to="/recuperar-contrasena">¿Olvidó su contraseña?</router-link>
                </div>
            </section>
            <div class="crear-cuenta">
                ¿No tienes una cuenta?
                <router-link to="/registro">Regístrate</router-link>
            </div>
        </div>
    
        <div v-if="infoModal" class="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
            <div class="caja-unlogin msg-info">
                <img src="@/assets/img/logo.png" class="mx-auto mt-2 mb-5" alt="Mas que lectura" />
                <p>{{ t(urlSub) }}</p>
                <div class="mt-4">
                    <router-link to="/">
                        <button class="blue" @click="infoModal = false">OK</button>
                    </router-link>
                </div>
            </div>
        </div>
    
    </div>
</template>

<script setup>
import { ref } from 'vue';
//import { storeToRefs } from 'pinia';
import { useAuthStore } from '../store/auth.store.js';
import { useLibrosStore } from '@/store/libros'
import { useQRStore } from '@/store/qr.store'
import router from '@/router';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n'
import { isValidTime } from '@/helpers';

const QrStore = useQRStore()
const LibrosStore = useLibrosStore()
let cargado = ref(false);
const url = useRoute();
const urlOk = url.query.ok;
const urlSub = url.query.sub;
console.log(urlOk);
console.log(urlSub);
const { t } = useI18n()

let infoModal = ref(false)
let mensaje = "";
console.log(mensaje)

if (urlOk === '1') {
    infoModal.value = true;
}

const form = ref({
    email: '',
    password: '',

});

const errorShow = ref(false);
const errorText = ref('');

const EsValido = isValidTime();
if (!EsValido) {
    cargado.value = true;
} else {
    LibrosStore.fetchLibros().then(() => {  
        LibrosStore.fetchRelatedBooks()
        if ( QrStore.codeQr == null ) {
            router.push('/home/')
        } else {
            router.push('/home/' + QrStore.codeQr)
        }        
    })
}


function onSubmit() {
    errorShow.value = false
    const authStore = useAuthStore();
    const QrStore = useQRStore()
    const { email, password } = form.value
    const LibrosStore = useLibrosStore()
    authStore.login(email, password).then(() => {
        errorShow.value = false
        QrStore.fetchQrDirect()
            .then(() => {
                LibrosStore.fetchLibros().then(() => {
                    LibrosStore.fetchRelatedBooks()
                    if ( QrStore.codeQr == null ) {
                        router.push('/home/')
                    } else {
                        router.push('/home/' + QrStore.codeQr)
                    }
                }).catch((error) => {
                    console.log(error)
                    let errorData = JSON.parse(error);
                    errorShow.value = true
                    errorText.value = errorData.message
                });
            })
            .catch(() => {
                LibrosStore.fetchLibros().then(() => {
                    LibrosStore.fetchRelatedBooks()
                    if ( QrStore.codeQr == null ) {
                        router.push('/home/')
                    } else {
                        router.push('/home/' + QrStore.codeQr)
                    }
                }).catch((error) => {
                    console.log(error)
                    let errorData = JSON.parse(error);
                    errorShow.value = true
                    errorText.value = errorData.message
                });
            })
    })
    .catch((error) => {
        console.log(error)
        let errorData = JSON.parse(error);
        errorShow.value = true
        errorText.value = errorData.message
    });
}

const showPassword = ref(false);
const activeField = ref('password');
import verPassImg from '@/assets/img/ver.png';
import esconderImg from '@/assets/img/esconder.png';
const showpassimg = ref(verPassImg);
const hidepassimg = ref(esconderImg);

function togglePasswordVisibility(field) {
    if (activeField.value === field) {
        showPassword.value = !showPassword.value;
    } else {
        showPassword.value = true;
        activeField.value = field;
    }
}
</script>


<style scoped>

</style>


  

   
